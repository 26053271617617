import React from 'react';

const BelowFooter: React.FC = () => {
  return (
    <div className="below-footer">
      <p className="disclaimer">
        The services offered by PINX Global Markets are provided by a registered business entity based in Czech Republic. Trading cryptocurrencies involves a high level of risk and may not be suitable for all investors.
        Please consult with an independent financial advisor if necessary.
        <br />
        PINX Global Markets does not provide investment, tax, or legal advice. Past performance is not indicative of future
        results.
      </p>
      <div className="copyright">
        © 2023-2025 PINXGlobal.com. All rights reserved.
      </div>

      <style jsx>{`

        .below-footer {
          padding: 20px 0;
          text-align: center;
          background: #000000; /* Very dark background */
          border-top: 1px solid rgba(255, 255, 255, 0.2); /* Semi-transparent white border */
        }
        .disclaimer {
          font-family: 'Roboto', sans-serif; /* New font for disclaimer */
          font-size: 14px;
          width: 90%;
          margin: 0 auto;
          color: #999999; /* Grey color for disclaimer text */
          margin-bottom: 20px; /* Added margin-bottom */
          font-weight: 600; /* Increased font-weight */
        }
        .copyright {
          padding-top: 10px;
          color: #ffffff; /* White text */
          border-top: 1px solid rgba(255, 255, 255, 0.2); /* Semi-transparent white border */
        }

        /* Media queries for responsive font sizing */
        @media (max-width: 1000px) {
          .disclaimer {
            font-size: 13px;
          }
        }

        @media (max-width: 800px) {
          .disclaimer {
            font-size: 12px;
          }
        }

        @media (max-width: 600px) {
          .disclaimer {
            font-size: 11px;
          }
        }

        @media (max-width: 400px) {
          .disclaimer {
            font-size: 10px;
          }
        }
      `}</style>
    </div>
  );
};

export default BelowFooter;

import React, { useEffect, useState, useRef } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import "@solana/wallet-adapter-react-ui/styles.css";

const ConnectWalletButton = ({setIsModalOpen}) => { 
  const { connected, publicKey } = useWallet();
  const [buttonText, setButtonText] = useState("Connect Wallet");
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    if (connected && publicKey) {
      const shortAddress = `${publicKey.toString().slice(0, 4)}...${publicKey.toString().slice(-4)}`;
      setButtonText(shortAddress);
    } else {
      setButtonText("Connect Wallet");
    }
  }, [connected, publicKey]);

  const handleButtonClick = () => {
    if (!setIsModalOpen) return;
    setIsModalOpen(false);  
  };


  if (!isClient) {
    return null; 
  }

  return (
    <div className="wallet-connection"  onClick={handleButtonClick}>
      <style>
        {`
          .wallet-adapter-button-trigger {
            background: linear-gradient(to bottom, #1e3c72, #2a5298) !important; 
            color: white !important;
            border: none !important;
            border-radius: 12px !important; 
            transition: background 0.3s ease, border-radius 0.3s ease !important;
          }

          /* Hover effect for the gradient */
          .wallet-adapter-button-trigger:hover {
            background: linear-gradient(to bottom, #1b2f58, #203a6e) !important; 
            border-radius: 14px !important; 
          }

          @media screen and (max-width: 1250px) {
            .wallet-adapter-button {
              padding: 0 4px !important;
              font-size: 14px !important;
              white-space: nowrap !important;
            }
          }

          @media screen and (max-width: 999px) {
            .wallet-adapter-button {
              padding: 0 14px !important;
              font-size: 22px;
            }
          }

          @media screen and (max-width: 800px) {
            .wallet-adapter-button {
              padding: 0 3vw !important;
              font-size: 3vw;
            }
          }

          @media screen and (max-width: 600px) {
            .wallet-adapter-button {
              font-size: 3.5vw;
            }
          }

          @media screen and (max-width: 600px) {
            .wallet-adapter-button {
              font-size: 4vw;
            }
          }
        `}
      </style>

      <WalletMultiButton>
        {buttonText}
      </WalletMultiButton>
    </div>
  );
};

export default ConnectWalletButton;

import React, { useEffect, useState } from 'react';
import Link from 'next/link';

const PinxPriceRow = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="pinx-price-row">
      <div className="pinx-price-container">
      <Link href="/presale" passHref>
          <a style={{ textDecoration: 'none' }}>
            <div className="price-display-button">
              <span className="price-text">BUY $PINX</span> 
            </div>
          </a>
        </Link>
      </div>
      <span className="live-timer">
        {time.toLocaleTimeString()}
      </span>

      <style jsx>{`
        .pinx-price-row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 0 10px;
          height: 60px; /* Ensures the height is 60px */
        }

        .price-display-button {
          display: flex;
          align-items: center;
          padding: 10px;
          background: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
          border-radius: 10px;
        }

        .pinx-text {
          color: #ff69b4;
          text-shadow: 0 0 8px rgba(255, 105, 180, 0.7);
          font-size: 2vw;
          margin-right: 10px;
        }

        .price-text {
          color: #00ff00;
          text-shadow: 0 0 8px rgba(0, 255, 0, 0.7);
          font-size: 2vw;
        }

        .live-timer {
          color: #ffffff;
          font-size: 2vw;
          cursor: none;
        }

        @media (max-width: 1000px) {
          .pinx-text, .price-text, .live-timer {
            font-size: 3vw;
          }
        }

        @media (max-width: 800px) {
          .pinx-text, .price-text, .live-timer {
            font-size: 4vw;
          }
        }

        @media (max-width: 600px) {
          .pinx-text, .price-text, .live-timer {
            font-size: 5vw;
          }
        }
      `}</style>
    </div>
  );
};

export default PinxPriceRow;

import React, { useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';

const BuyPinxDropdown = () => {
  const [isBuyHovered, setIsBuyHovered] = useState(false);

  return (
    <div
      className={`nav-dropdown-container ${isBuyHovered ? 'modalSelected' : ''}`}
      onMouseEnter={() => setIsBuyHovered(true)}
      onMouseLeave={() => setIsBuyHovered(false)}
    >
      Trade
      <svg
        className={`nav-dropdown-arrow ${isBuyHovered ? 'rotate' : ''}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path d="M5 8l5 5 5-5H5z" />
      </svg>
      {isBuyHovered && (
        <div className="nav-dropdown-content">
<Link href="/swap" passHref>
  <a style={{ textDecoration: 'none' }}>
    <div className="nav-body-item">
      <div className="dropdown-icon">
        <Image src="/assets/swap.gif" alt="Swap Icon" width={40} height={40} />
      </div>
      <div className="dropdown-content">
        <div className="dropdown-title">
          Trade Tokenized Assets
        </div>
        <div className="dropdown-subtitle">
          Swap tokenized stocks seamlessly using deep liquidity pools for efficient trades.
        </div>
      </div>
      <div className="dropdown-svg">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path d="M8 5l5 5 -5 5V5z" />
        </svg>
      </div>
    </div>
  </a>
</Link>


          <Link href="/TTD" passHref>
            <a style={{ textDecoration: 'none' }}>
              <div className="nav-body-item">
                <div className="dropdown-icon">
                  <Image src="/assets/lock-lines.png" alt="On Demand Trading Icon" width={40} height={40} />
                </div>
                <div className="dropdown-content">
                  <div className="dropdown-title">
                    Tokenised Trading Desk
                  </div>
                  <div className="dropdown-subtitle">
                    Access advanced tools for escrow-based, on-demand trading securely and efficiently.
                  </div>
                </div>
                <div className="dropdown-svg">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M8 5l5 5 -5 5V5z" />
                  </svg>
                </div>
              </div>
            </a>
          </Link>


          <Link href="/wallet" passHref>
            <a style={{ textDecoration: 'none' }}>
              <div className="nav-body-item">
                <div className="dropdown-icon">
                  <Image src="/assets/digital-wallet.png" alt="Wallet Icon" width={40} height={40} />
                </div>
                <div className="dropdown-content">
                  <div className="dropdown-title">
                    Digital Wallet
                  </div>
                  <div className="dropdown-subtitle">
                    Securely manage your funds, transactions, and digital assets with ease.
                  </div>
                </div>
                <div className="dropdown-svg">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M8 5l5 5 -5 5V5z" />
                  </svg>
                </div>
              </div>
            </a>
          </Link>


          <Link href="/demo" passHref>
            <a style={{ textDecoration: 'none' }}>
              <div className="nav-body-item">
                <div className="dropdown-icon">
                  <Image src="/assets/buy.png" alt="Demo Trading Icon" width={40} height={40} />
                </div>
                <div className="dropdown-content">
                  <div className="dropdown-title">
                    Demo Trading
                  </div>
                  <div className="dropdown-subtitle">
                    Refine your skills by trading with Solana tokens in a risk-free environment.
                  </div>
                </div>
                <div className="dropdown-svg">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M8 5l5 5 -5 5V5z" />
                  </svg>
                </div>
              </div>
            </a>
          </Link>
        </div>
      )}
    </div>
  );
};

export default BuyPinxDropdown;
